<template data-app>
  <div class="dashboard-content">
    <!-- Content -->
    <div class="card">
      <div class="filters my-2" style="padding: 10px 10px 0px 10px">
        <h3 class="text-center">
          {{ $t('Views.AdminCategories.title')
          }}<v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="#0c2c7c"
                dark
                class="mb-2"
                style="float: right"
                @click.prevent="handleCreate()"
                >{{ $t('Views.AdminCategories.createButton') }}
              </v-btn></template
            ><span>{{ $t('Views.AdminCategories.createText') }}</span></v-tooltip
          >
        </h3>
        <label>{{ $t('Views.AdminCategories.filterText') }}:</label>

        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="my-2"
          ><v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                :label="$t('Views.AdminCategories.filterSearch')"
                v-model="filters.search"
              ></v-text-field>
            </v-col>
            <v-btn
              color="#0c2c7c"
              style="min-width: auto; position: absolute; right: 50px; height: 25px"
              dark
              class="px-1 py-1"
              v-if="filterActivated"
              @click.prevent="
                filterActivated = false
                cleanFilters()
                getCategories()
              "
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="#0c2c7c"
                  style="min-width: auto; position: absolute; right: 10px; height: 25px"
                  dark
                  class="px-1 py-1"
                  @click.prevent="
                    filterActivated = true
                    getCategories()
                  "
                  ><v-icon dark> mdi-filter </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Views.AdminCategories.filterButtonText') }}</span>
            </v-tooltip>
          </v-row>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="false"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :hideFooter="true"
        :multiSort="false"
        :loading="loading"
        :class="'mb-4'"
        :sortable="true"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="true"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </div>
    <modal name="crudModal" :height="'75%'" :width="'80%'" :adaptive="true">
      <h3 class="text-center" v-if="flow == 'create'">
        {{ $t('Views.AdminCategories.createText') }}
      </h3>
      <h3 class="text-center" v-if="flow == 'edit'">
        {{ $t('Views.AdminCategories.editText') }}
      </h3>
      <div class="card" style="padding: 2rem; margin: 20px">
        <v-form ref="formCompany" v-model="valid" style="width: 100%">
          <v-row class="mx-0">
            <v-col cols="12" md="8" sm="12" class="mx-auto">
              <v-text-field
                :rules="[rules.required, rules.max]"
                :label="$t('Views.AdminCategories.labelName')"
                v-model="name"
                :counter="80"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </div>

      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            :dark="valid"
            style="margin: auto; margin-top: 10px"
            v-if="flow == 'create'"
            :disabled="!valid"
            color="#0c2c7c"
            @click="createItem()"
          >
            {{ $t('Views.AdminCategories.createButton') }}
          </v-btn>
          <v-btn
            :dark="valid"
            style="margin: auto; margin-top: 10px"
            v-if="flow == 'edit'"
            :disabled="!valid"
            color="#0c2c7c"
            @click="saveItem()"
          >
            {{ $t('Views.AdminCategories.saveButton') }}
          </v-btn>

          <v-btn
            dark
            style="margin: auto; margin-top: 10px"
            color="#0c2c7c"
            @click="reset"
          >
            {{ $t('Views.AdminCategories.cleanButton') }}
          </v-btn>
          <v-btn
            dark
            style="margin: auto; margin-top: 10px"
            color="#0c2c7c"
            @click="$modal.hide('crudModal')"
          >
            {{ $t('Views.AdminCategories.cancelButton') }}
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import SimpleList from 'Components/Listing/SimpleList.vue'

  export default {
    name: 'Categories',
    components: {
      SimpleList,
    },
    data() {
      return {
        loading: false,
        items: [],
        users: [],
        companies: [],
        selectedCategories: [],
        selectedCountries: [],
        countries: [],
        categories: [],
        page: 1,
        pages: 1,
        item: {},
        filterActivated: false,
        filters: {
          search: '',
          type: null,
        },
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        name: '',
        type: '',
        image: null,
        loadingText: 'Cargando registros',
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => v.length >= 8 || '8 caracteres como mínimo',
          max: (v) => v.length <= 80 || 'Debe poser menos de 80 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => this.isURL(v) || 'La URL es inválida',
        },
      }
    },
    created() {
      this.getCategories()
    },
    computed: {
      headers() {
        return [
          { text: this.$t('Views.AdminCategories.headerName'), value: 'name' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '100px' },
        ]
      },
      orderOptions() {
        return [
          {
            id: 1,
            name: this.$t('Views.AdminCategories.orderOption1'),
            order: 'created_at',
            by: 'desc',
          },
          {
            id: 2,
            name: this.$t('Views.AdminCategories.orderOption2'),
            order: 'created_at',
            by: 'asc',
          },
          {
            id: 3,
            name: this.$t('Views.AdminCategories.orderOption3'),
            order: 'name',
            by: 'asc',
          },
          {
            id: 4,
            name: this.$t('Views.AdminCategories.orderOption4'),
            order: 'updated_at',
            by: 'desc',
          },
        ]
      },
      orderBy() {
        return {
          id: 3,
          name: this.$t('Views.AdminCategories.orderOption3'),
          order: 'name',
          by: 'asc',
        }
      },
    },
    methods: {
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getCategories()
      },
      fullName({ name, lastname, email }) {
        if (name && name != 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },
      cleanFilters() {
        this.filters = {
          search: '',
          type: null,
          author: null,
          outstanding: null,
          date: null,
          active: null,
        }
      },

      async getCategories() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          if (this.filters.search != '') {
            searchString = '&search=' + this.filters.search
          }
          queryParams = searchString + typeString
        }
        axios
          .get('categories?type=0&page=' + this.page + orderString + queryParams)
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.name = ''
        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = this.item.name

        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.form.validate()
      },

      reset() {
        this.$refs.form.reset()
      },

      async createItem() {
        axios.post('categories', { name: this.name, type: '0' }).then(() => {
          this.page = 1
          this.getCategories()
          this.$modal.hide('crudModal')
        })
      },

      async saveItem() {
        axios
          .patch('categories/' + this.item.id, { name: this.name, type: '0' })
          .then((response) => {
            this.page = 1
            this.getCategories()
            this.$modal.hide('crudModal')
          })
      },

      async deleteItem(item) {
        this.loading = true
        axios.delete('categories/' + item.id).then((response) => {
          this.page = 1
          this.items = []
          this.getCategories()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getCategories()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/cruds/_crudStyles.scss';
</style>
